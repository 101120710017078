import React, { useEffect, useState } from "react";
import "./asset-management.css";
import "../assets/css/main.css";
import { AppSettings } from "../../constant";
import Select from "react-dropdown-select";
import { toast } from "react-toastify";
import { NavLink } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import ViewDetail from "../view-detail/view-detail";
import History from "../history/history";
import AllcoateAsset from "../allocate-asset/allocate-asset";
// import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
// import Tooltip from 'react-bootstrap/Tooltip';
import Tooltip from "@mui/material/Tooltip";
import ExcelExportButton from "../excel-export/ExcelExportButton";
import axiosInstance from "../../axios-interceptor";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

function AssetManagement() {
  const [currentAsset, setCurrentAsset] = useState(
    localStorage.getItem("currentAsset")
      ? localStorage.getItem("currentAsset")
      : "CPU"
  );
  const customId = "custom-id-yes";
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [sidebarAction, setSidebarAction] = useState("");
  const [inputValues, setInputValues] = useState({});
  const [editItemId, setEditItemId] = useState(null);
  const [dropdownOptions, setDropdownOptions] = useState({});
  const [modalShow, setModalShow] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [addProp, setAddProp] = useState([]);
  const [filterUser, setFilterUser] = useState({
    value: "All",
    label: "All",
  });
  const [dataError, setDataError] = useState({
    //network and other assets
    assetType: false,
    diskName: false,
    networkPort: false,
    imei: false,
    location: false,
    model: false,
    wiredStatus: false,
    os: false,
    //
    // biosVersion: false,
    brand: false,
    generation: false,
    // macId: false,
    processor: false,
    // purchaseDate: false,
    ram: false,
    serialNo: false,
    systemId: false,
    modelNo: false,
    storageSize: false,
    storageType: false,
    windowsOS: false,
    // warrantyTill: false,
    isAssigned: false,
    // isActive: false,
    // createdBy: false,
    // createdOn: false,
    // isMonitor: false,
    isKeyboard: false,
    dimension: false,
    isMouse: false,
    isHeadSet: false,
    isAVInstalled: false,
    windowsLicense: false,
    isLicensed: false,
    isActive: false,
    ipAddress: false,
    // remarks: false,
    screensize: false,
    isWarrantyTill: false,
  });

  const [selectedValue, setSelectedValue] = useState({
    //network and other asset
    assetType: { value: null, label: "Network Asset Type" },
    diskName: { value: null, label: "" },
    networkPort: { value: null, label: "" },
    imei: { value: null, label: "" },
    location: { value: null, label: "" },
    model: { value: null, label: "" },
    wiredStatus: { value: null, label: "Wired status" },
    os: { value: null, label: "" },
    //
    serialNo: { value: null, label: "" },
    systemId: { value: null, label: "" },
    modelNo: { value: null, label: "" },
    macId: { value: null, label: "" },
    biosVersion: { value: null, label: "" },
    brand: { value: null, label: "" },
    ram: { value: null, label: "" },
    processor: { value: null, label: "" },
    generation: { value: null, label: "" },
    storageType: { value: null, label: "Storage Type" },
    windowsOS: { value: null, label: "Os Type" },
    storageSize: { value: null, label: "" },
    screensize: { value: null, label: "" },
    purchaseDate: { value: null, label: "" },
    isWarrantyTill: { value: null, label: "" },
    warrantyTill: { value: null, label: "warranty" },
    // isActive: { value: null, label: "Is Active" },
    // createdBy: { value: null, label: "" },
    createdOn: { value: null, label: "" },
    // isMonitor: { value: null, label: "is Monitor" },
    isKeyboard: { value: null, label: "Keyboard" },
    dimension: { value: null, label: "" },
    isMouse: { value: null, label: "Mouse" },
    isHeadSet: { value: null, label: "HeadSet" },
    isAVInstalled: { value: null, label: "AVInstalled" },
    isLicensed: {
      value: null,
      label:
        inputValues?.windowsOS === "Mac Os" ||
        inputValues?.windowsOS === "Windows" ||
        inputValues?.windowsOS === undefined ||
        inputValues?.windowsOS === null ||
        ""
          ? "Licensed/Not"
          : "License Type",
    },
    windowsLicense: { value: null, label: "" },
    isActive: { value: null, label: "State" },
    remarks: { value: null, label: "" },
    ipAddress: { value: null, label: "" },
  });
  const [searchFilter, setSearchFilter] = useState("");

  const handleAssetClick = (event) => {
    setData([]);
    setFilteredData([]);
    if (event !== currentAsset) {
      fetchData(event);
    }
    setSearchFilter("");
    setCurrentAsset(event);
    localStorage.setItem("currentAsset", event);
    if (event === "Network") {
      fetchDropdownOptions("Network/GetAllNetworkAssetTypeMaster");
    } else if (event === "OtherAsset") {
      fetchDropdownOptions("OtherAsset/GetAllOtherAssetTypeMaster");
    }
    setCurrentPage(1);
  };

  useEffect(() => {
    fetchData(currentAsset);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchData = async (event) => {
    try {
      const res = await axiosInstance.get(
        AppSettings.URLs.asset + event + "/GetAllActive" + event + "sAsync"
      );

      if (res != null) {
        if (event === "OtherAsset") {
          setData(
            res?.data?.response?.[event.split("Other")[1].toLowerCase() + "s"]
          );
          setFilteredData(
            res?.data?.response?.[event.split("Other")[1].toLowerCase() + "s"]
          );
          setLoading(true);
        } else {
          setData(res?.data?.response?.[event.toLowerCase() + "s"]);
          setFilteredData(res?.data?.response?.[event.toLowerCase() + "s"]);
          setLoading(true);
        }
      }
    } catch (err) {
      console.log(err.message);
    }
  };

  const refreshData = (event) => {
    fetchData(event);
  };

  //search functionality
  const handleSearch = (value, filter) => {
    const temp = data.filter((object) => {
      for (const key in object) {
        if (object.hasOwnProperty(key) && typeof object[key] === "string") {
          if (object[key].toLowerCase().includes(value?.toLowerCase())) {
            return true;
          }
        }
      }
      return false;
    });
    let newFilteredData;
    if (filter !== "All") {
      newFilteredData = temp.filter((item) => item.isActive === filter);
    } else {
      newFilteredData = temp;
    }
    setFilteredData(newFilteredData);
    setCurrentPage(1);
  };

  // To get dropdown data
  const fetchDropdownOptions = async (type) => {
    try {
      if (type === "Network/GetAllNetworkAssetTypeMaster") {
        const networkType = await axiosInstance.get(
          AppSettings.URLs.asset + type
        );
        setDropdownOptions({
          network:
            networkType?.data?.response?.assets || [],
        });
      } else if (type === "OtherAsset/GetAllOtherAssetTypeMaster") {
        const otherAssetType = await axiosInstance.get(
          AppSettings.URLs.asset + type
        );
        setDropdownOptions({
          other:
            otherAssetType?.data?.response?.assets || [],
        });
      }
    } catch (error) {
      console.log("Error fetching dropdown options:", error);
    }
  };

  //Pagination
  const itemsPerPage = 10;
  const totalPages = filteredData
    ? Math.ceil(filteredData?.length / itemsPerPage)
    : 0;
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = currentPage * itemsPerPage;
  const displayedData = filteredData
    ? filteredData.slice(startIndex, endIndex)
    : [];
  const goToPreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };
  const goToNextPage = () => {
    if (endIndex < filteredData?.length) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };
  const renderPageNumbers = () => {
    const maxPageNumbersToShow = 3; // Adjust this value as per your preference
    const pageNumbers = [];
    let startPage;
    let endPage;

    if (totalPages <= maxPageNumbersToShow) {
      // Show all page numbers if the total pages are less than or equal to the maxPageNumbersToShow
      startPage = 1;
      endPage = totalPages;
    } else {
      // Calculate the start and end page numbers with an ellipsis
      const middlePage = Math.ceil(maxPageNumbersToShow / 2);
      if (currentPage <= middlePage) {
        startPage = 1;
        endPage = maxPageNumbersToShow;
      } else if (currentPage + middlePage - 1 >= totalPages) {
        startPage = totalPages - maxPageNumbersToShow + 1;
        endPage = totalPages;
      } else {
        startPage = currentPage - middlePage + 1;
        endPage = currentPage + middlePage - 1;
      }
    }

    // Add ellipsis at the beginning if needed
    if (startPage > 1) {
      pageNumbers.push(1);
      if (startPage > 2) {
        pageNumbers.push("...");
      }
    }

    // Add page numbers between startPage and endPage
    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }

    // Add ellipsis at the end if needed
    if (endPage < totalPages) {
      if (endPage < totalPages - 1) {
        pageNumbers.push("...");
      }
      pageNumbers.push(totalPages);
    }

    return pageNumbers.map((pageNumber) =>
      typeof pageNumber === "number" ? (
        <li
          key={pageNumber}
          className={`inline-block cursor-pointer hover:backdrop-brightness-90 ${
            currentPage === pageNumber ? "current" : ""
          }`}
          onClick={() => setCurrentPage(pageNumber)}
        >
          <span>{pageNumber}</span>
        </li>
      ) : (
        <li key={pageNumber} className="inline-block mx-1">
          {pageNumber}
        </li>
      )
    );
  };

  ///filter status

  const handleFilterChange = (type) => {
    let newFilteredData;
    // if (type === "All") {
    //   alert("all");
    //   newFilteredData = displayedData;
    // } else if (type === "Active") {
    //   alert("active");
    //   newFilteredData = displayedData.filter((data) => data.isActive);
    // } else {
    //   alert("isactive");
    //   newFilteredData = displayedData.filter((data) => !data.isActive);
    // }

    // setFilteredData(newFilteredData);
    // Update state with the filtered data
  };

  //To Open sideBar
  const handleSidebarOpen = async (action, item) => {
    setIsSidebarOpen(true);
    setSidebarAction(action);
    setEditItemId(item?.id);
    if (item !== null) {
      setInputValues({
        ...item,
        systemId: item.systemId.slice(7),
      });
      setSelectedValue({
        //network and other asset
        assetType: { value: item?.assetType, label: item?.assetType },
        diskName: { value: item?.diskName, label: item?.diskName },
        networkPort: { value: item?.networkPort, label: item?.networkPort },
        imei: { value: item?.imei, label: item?.imei },
        location: { value: item?.location, label: item?.location },
        model: { value: item?.model, label: item?.model },
        wiredStatus: { value: item?.wiredStatus, label: item?.wiredStatus },
        os: { value: item?.os, label: item?.os },
        //
        serialNo: { value: item?.serialNo, label: item?.serialNo },
        systemId: {
          value: item?.systemId.slice(7),
          label: item?.systemId.slice(7),
        },
        modelNo: { value: item?.modelNo, label: item?.modelNo },
        macId: { value: item?.macId, label: item?.macId },
        biosVersion: { value: item?.biosVersion, label: item?.biosVersion },
        dimension: { value: item?.dimension, label: item?.dimension },
        brand: { value: item?.brand, label: item?.brand },
        ram: { value: item?.ram, label: item?.ram },
        processor: { value: item?.processor, label: item?.processor },
        generation: { value: item?.generation, label: item?.generation },
        storageType: { value: item?.storageType, label: item?.storageType },
        windowsOS: { value: item?.windowsOS, label: item?.windowsOS },
        storageSize: { value: item?.storageSize, label: item?.storageSize },
        screensize: { value: item?.screensize, label: item?.screensize },
        purchaseDate: {
          value: item?.purchaseDate,
          label: item?.purchaseDate?.split("T")[0],
        },
        warrantyTill: {
          value: item?.warrantyTill,
          label: item?.warrantyTill?.split("T")[0],
        },
        // isActive: {
        //   value: item?.isActive,
        //   label: item?.isActive === true ? "Yes" : "No",
        // },
        // createdBy: { value: item?.createdBy, label: item?.createdBy },
        createdOn: {
          value: item?.createdOn,
          label: item?.createdOn?.split("T")[0],
        },
        // isMonitor: {
        //   value: item?.isMonitor,
        //   label: item?.isMonitor === true ? "Yes" : "No",
        // },
        isKeyboard: {
          value: item?.isKeyboard,
          label: item?.isKeyboard === true ? "Yes" : "No",
        },
        isMouse: {
          value: item?.isMouse,
          label: item?.isMouse === true ? "Yes" : "No",
        },
        isHeadSet: {
          value: item?.isHeadSet,
          label: item?.isHeadSet === true ? "Yes" : "No",
        },
        isAVInstalled: {
          value: item?.isAVInstalled,
          label: item?.isAVInstalled === true ? "Yes" : "No",
        },
        isLicensed: {
          value: item?.isLicensed,
          label:
            item?.isLicensed === true
              ? inputValues?.windowsOS === "Linux"
                ? "Paid Version"
                : "yes"
              : inputValues?.windowsOS === "Linux"
              ? "Free Version"
              : "No",
        },

        isWarrantyTill: {
          value: item?.isWarrantyTill,
          label: item?.isWarrantyTill === true ? "Yes" : "No",
        },
        windowsLicense: {
          value: item?.windowsLicense,
          label: item?.windowsLicense,
        },
        isActive: {
          value: item?.isActive,
          label: item?.isActive === true ? "Active" : "In Active",
        },
        remarks: { value: item?.remarks, label: item?.remarks },
        ipAddress: { value: item?.ipAddress, label: item?.ipAddress },
      });
    } else {
      setInputValues((values) => ({
        ...values,
        // purchaseDate: new Date().toISOString().substr(0, 10),
        // warrantyTill: new Date(
        //   new Date().setFullYear(new Date().getFullYear() + 2)
        // )
        //   .toISOString()
        //   .substr(0, 10),
        purchaseDate: null,
        warrantyTill: null,
        remarks: "",
      }));

      setSelectedValue({
        // network and other asset
        assetType: { value: null, label: "Type" },
        diskName: { value: null, label: "" },
        networkPort: { value: null, label: "" },
        imei: { value: null, label: "" },
        location: { value: null, label: "" },
        model: { value: null, label: "" },
        wiredStatus: { value: null, label: "Wired status" },
        os: { value: null, label: "" },
        //
        serialNo: { value: null, label: "" },
        systemId: { value: null, label: "" },
        modelNo: { value: null, label: "" },
        macId: { value: null, label: "" },
        biosVersion: { value: null, label: "" },
        brand: { value: null, label: "" },
        dimension: { value: null, label: "" },
        ram: { value: null, label: "" },
        processor: { value: null, label: "" },
        generation: { value: null, label: "" },
        storageType: { value: null, label: "Storage Type" },
        windowsOS: { value: null, label: "OS Type" },
        storageSize: { value: null, label: "" },
        screensize: { value: null, label: "" },
        purchaseDate: {
          value: null,
          label: "Purchase Date",
        },
        warrantyTill: { value: null, label: "Warranty Till" },
        isWarrantyTill: { value: null, label: "Warranty" },
        // isActive: { value: null, label: "Is Active" },
        // createdBy: { value: null, label: "" },
        createdOn: { value: null, label: "" },
        // isMonitor: { value: null, label: "is Monitor" },
        isKeyboard: { value: null, label: "Keyboard" },
        isMouse: { value: null, label: "Mouse" },
        isHeadSet: { value: null, label: "HeadSet" },
        isAVInstalled: { value: null, label: "AVInstalled" },
        isLicensed: {
          value: null,
          label:
            inputValues.windowsOS === "Mac Os" ||
            inputValues.windowsOS === "Windows" ||
            inputValues.windowsOS === undefined ||
            inputValues.windowsOS === null ||
            ""
              ? "Licensed/Not"
              : "License Type",
        },
        windowsLicense: { value: null, label: "" },
        isActive: { value: null, label: "State" },
        remarks: { value: null, label: "" },
        ipAddress: { value: null, label: "" },
      });
    }
  };

  //To Close SideBar
  const handleSidebarClose = () => {
    setIsSidebarOpen(false);
    setSidebarAction("");
    setEditItemId(null);
    setInputValues({});
    setSelectedValue({
      //network and otherAssset
      assetType: { value: null, label: "Type" },
      diskName: { value: null, label: "" },
      networkPort: { value: null, label: "" },
      imei: { value: null, label: "" },
      location: { value: null, label: "" },
      model: { value: null, label: "" },
      wiredStatus: { value: null, label: "Wired Status" },
      os: { value: null, label: "" },
      //
      serialNo: { value: null, label: "" },
      systemId: { value: null, label: "" },
      modelNo: { value: null, label: "" },
      macId: { value: null, label: "" },
      biosVersion: { value: null, label: "" },
      brand: { value: null, label: "" },
      dimension: { value: null, label: "" },
      ram: { value: null, label: "" },
      processor: { value: null, label: "" },
      generation: { value: null, label: "" },
      storageType: { value: null, label: "Storage Type" },
      windowsOS: { value: null, label: "OS Type" },
      storageSize: { value: null, label: "" },
      screensize: { value: null, label: "" },
      purchaseDate: { value: null, label: "" },
      warrantyTill: { value: null, label: "" },
      isWarrantyTill: { value: null, label: "Warranty" },
      // isActive: { value: null, label: "Is Active" },
      // createdBy: { value: null, label: "" },
      createdOn: { value: null, label: "" },
      // isMonitor: { value: null, label: "is Monitor" },
      isKeyboard: { value: null, label: "Keyboard" },
      isMouse: { value: null, label: "Mouse" },
      isHeadSet: { value: null, label: "HeadSet" },
      isAVInstalled: { value: null, label: "AVInstalled" },
      isLicensed: {
        value: null,
        label:
          inputValues.windowsOS === "Mac Os" ||
          inputValues.windowsOS === "Windows" ||
          inputValues.windowsOS === undefined ||
          inputValues.windowsOS === null ||
          ""
            ? "Licensed/Not"
            : "License Type",
      },
      windowsLicense: { value: null, label: "" },
      isActive: { value: null, label: "State" },
      remarks: { value: null, label: "" },
      ipAddress: { value: null, label: "" },
    });
    setDataError({
      //network and other assets
      assetType: false,
      diskName: false,
      networkPort: false,
      imei: false,
      location: false,
      model: false,
      wiredStatus: false,
      os: false,
      //
      // biosVersion: false,
      brand: false,
      dimension: false,
      generation: false,
      // macId: false,
      processor: false,
      // purchaseDate: false,
      ram: false,
      serialNo: false,
      systemId: false,
      modelNo: false,
      storageSize: false,
      storageType: false,
      windowsOS: false,
      // warrantyTill: false,
      isWarrantyTill: false,
      isAssigned: false,
      // isActive: false,
      // createdBy: false,
      // createdOn: false,
      // isMonitor: false,
      isKeyboard: false,
      isMouse: false,
      isHeadSet: false,
      isAVInstalled: false,
      // windowsLicense: false,
      // isLicensed: false,
      isActive: false,
      ipAddress: false,
      // remarks: false,
      screensize: false,
    });
  };

  // To handle the input form the user
  const handleInputChange = (e, name) => {
    let value;
    if (e && e.target) {
      // Regular input field
      if (e.target.type === "radio") {
        // For radio buttons, use the selected option's value
        if (e.target.checked) {
          value = parseInt(e.target.value);
          setSelectedValue((option) => ({
            ...option,
            [name]: { value: null, label: value },
          }));
        } else {
          // If the radio button is unchecked, set the value to an empty string or null, depending on your requirements

          value = "";
          setSelectedValue((option) => ({
            ...option,
            [name]: { value: null, label: value },
          }));
        }
      } else {
        value = e.target.value;
        setSelectedValue((option) => ({
          ...option,
          [name]: { value: null, label: value },
        }));
      }
    } else {
      // React Select component
      value = e ? e[0].value : "";
      setSelectedValue((option) => ({
        ...option,
        [name]: { value: e ? e[0].value : "", label: e ? e[0]?.label : "" },
      }));
    }
    setInputValues((prevInputValues) => ({
      ...prevInputValues,
      [name]: value,
      createdOn: new Date().toISOString().substr(0, 10),
    }));
    if (sidebarAction === "edit") {
      setInputValues((prevInputValues) => ({
        ...prevInputValues,
        changeInRemarks: true,
      }));
    }
    console.log(inputValues);
  };

  //To handle submit action of Sidebar
  const handleSidebarFormSubmit = (event) => {
    // event.preventDefault();

    let isFormFilled = false;
    setDataError({
      // network and other asset
      assetType:
        inputValues?.assetType === undefined || inputValues?.assetType === null
          ? true
          : false,
      diskName:
        inputValues?.diskName === undefined ||
        inputValues?.diskName === "" ||
        inputValues?.diskName === null
          ? true
          : false,
      networkPort:
        inputValues?.networkPort === undefined ||
        inputValues?.networkPort === "" ||
        inputValues?.networkPort === null
          ? true
          : false,
      imei:
        inputValues?.imei === undefined ||
        inputValues?.imei === "" ||
        inputValues?.imei === null
          ? true
          : false,
      location:
        inputValues?.location === undefined ||
        inputValues?.location === "" ||
        inputValues?.location === null
          ? true
          : false,
      model:
        inputValues?.model === undefined ||
        inputValues?.model === "" ||
        inputValues?.model === null
          ? true
          : false,
      wiredStatus:
        inputValues?.wiredStatus === undefined ||
        inputValues?.wiredStatus === "" ||
        inputValues?.wiredStatus === null
          ? true
          : false,
      os:
        inputValues?.os === undefined ||
        inputValues?.os === "" ||
        inputValues?.os === null
          ? true
          : false,
      //
      // biosVersion: inputValues?.biosVersion === undefined ? true : false,
      brand:
        inputValues?.brand === undefined ||
        inputValues?.brand === "" ||
        inputValues?.brand === null
          ? true
          : false,
      dimension:
        inputValues?.dimension === undefined ||
        inputValues?.dimension === "" ||
        inputValues?.dimension === null
          ? true
          : false,
      generation:
        inputValues?.generation === undefined ||
        inputValues?.generation === "" ||
        inputValues?.generation === null
          ? true
          : false,
      // macId:
      //   inputValues?.macId === undefined ||
      //   inputValues?.macId === "" ||
      //   inputValues?.macId === null
      //     ? true
      //     : false,
      processor:
        inputValues?.processor === undefined ||
        inputValues?.processor === "" ||
        inputValues?.processor === null
          ? true
          : false,
      ram:
        inputValues?.ram === undefined ||
        inputValues?.ram === "" ||
        inputValues?.ram === null
          ? true
          : false,
      isWarrantyTill:
        inputValues?.isWarrantyTill === undefined ||
        inputValues?.isWarrantyTill === null
          ? true
          : false,
      // purchaseDate:
      //   (inputValues?.purchaseDate === undefined ||
      //   inputValues?.purchaseDate === "0001-01-01")
      //     ? true
      //     : false,
      // warrantyTill:
      //   (inputValues?.warrantyTill === undefined ||
      //   inputValues?.warrantyTill === "0001-01-01")
      //     ? true
      //     : false,
      // createdOn:
      //   (inputValues?.createdOn === undefined ||
      //   inputValues?.createdOn === "0001-01-01")
      //     ? true
      //     : false,
      serialNo:
        inputValues?.serialNo === undefined ||
        inputValues?.serialNo === "" ||
        inputValues?.serialNo === null
          ? true
          : false,
      systemId:
        inputValues?.systemId === undefined ||
        inputValues?.systemId === "" ||
        inputValues?.systemId === null
          ? true
          : false,
      modelNo:
        inputValues?.modelNo === undefined ||
        inputValues?.modelNo === "" ||
        inputValues?.modelNo === null
          ? true
          : false,
      storageSize:
        inputValues?.storageSize === undefined ||
        inputValues?.storageSize === "" ||
        inputValues?.storageSize === null
          ? true
          : false,
      storageType:
        inputValues?.storageType === undefined ||
        inputValues?.storageType === null
          ? true
          : false,
      windowsOS:
        inputValues?.windowsOS === undefined || inputValues?.windowsOS === null
          ? true
          : false,
      // isActive: inputValues?.isActive === undefined ? true : false,
      // createdBy: (inputValues?.createdBy === undefined||inputValues?.createdBy === "") ? true : false,
      // isMonitor: inputValues?.isMonitor === undefined ? true : false,
      screensize:
        inputValues?.screensize === undefined ||
        inputValues?.screensize === "" ||
        inputValues?.screensize === null
          ? true
          : false,
      isKeyboard:
        inputValues?.isKeyboard === undefined ||
        inputValues?.isKeyboard === null
          ? true
          : false,
      isMouse:
        inputValues?.isMouse === undefined || inputValues?.isMouse === null
          ? true
          : false,
      isHeadSet:
        inputValues?.isHeadSet === undefined || inputValues?.isHeadSet === null
          ? true
          : false,
      isAVInstalled:
        inputValues?.isAVInstalled === undefined ||
        inputValues?.isAVInstalled === null
          ? true
          : false,
      // isLicensed:
      //   inputValues?.isLicensed === undefined ||
      //   inputValues?.isLicensed === null
      //     ? true
      //     : false,
      // windowsLicense:
      //   inputValues?.windowsLicense === undefined ||
      //   inputValues?.windowsLicense === "" ||
      //   inputValues?.windowsLicense === null
      //     ? true
      //     : false,

      isActive:
        inputValues?.isActive === undefined || inputValues?.isActive === null
          ? true
          : false,
      // ipAddress:
      //   inputValues?.ipAddress === undefined ||
      //   inputValues?.ipAddress === "" ||
      //   inputValues?.ipAddress === null
      //     ? true
      //     : false,
      // remarks: (inputValues?.remarks === undefined||inputValues?.remarks === "") ? true : false,
    });

    if (
      inputValues?.serialNo !== undefined &&
      inputValues?.serialNo !== "" &&
      (currentAsset !== "Network"
        ? inputValues?.systemId !== undefined && inputValues?.systemId !== ""
        : true) &&
      // (currentAsset==="Network" || "OtherAsset"?(inputValues?.model !== undefined&&inputValues?.model !== ""):(inputValues?.modelNo !== undefined&&inputValues?.modelNo !== "")) &&
      // ( currentAsset==="Network" || "OtherAsset"?true:(inputValues?.brand !== undefined&&inputValues?.brand !== "")) &&
      // inputValues?.isActive !== undefined &&
      // (inputValues?.createdBy !== undefined&&inputValues?.createdBy !== "") &&
      inputValues?.isActive !== undefined &&
      inputValues?.isWarrantyTill !== undefined &&
      inputValues?.isWarrantyTill !== null &&
      (inputValues?.isWarrantyTill === false ||
        (inputValues?.warrantyTill !== undefined &&
          inputValues?.warrantyTill !== ""))

      // (inputValues?.remarks !== undefined&&inputValues?.remarks !== "") &&
      // inputValues?.purchaseDate !== undefined &&
      // inputValues?.purchaseDate !== "0001-01-01" &&
      // inputValues?.createdOn !== undefined &&
      // inputValues?.createdOn !== "0001-01-01" &&
      // inputValues?.warrantyTill !== undefined &&
      // inputValues?.warrantyTill !== "0001-01-01"
    ) {
      if (currentAsset === "CPU" || currentAsset === "Laptop") {
        if (
          // inputValues?.biosVersion !== undefined &&
          // inputValues?.biosVersion !== "" &&
          inputValues?.processor !== undefined &&
          inputValues?.processor !== "" &&
          inputValues?.ram !== undefined &&
          inputValues?.ram !== "" &&
          // inputValues?.isMonitor !== undefined &&
          inputValues?.isKeyboard !== undefined &&
          inputValues?.storageSize !== undefined &&
          inputValues?.storageSize !== "" &&
          inputValues?.storageType !== undefined &&
          inputValues?.windowsOS !== undefined &&
          inputValues?.generation !== undefined &&
          inputValues?.generation !== "" &&
          inputValues?.isMouse !== undefined &&
          inputValues?.isHeadSet !== undefined &&
          inputValues?.isAVInstalled !== undefined
          // inputValues?.isLicensed !== undefined &&
          // (inputValues?.isLicensed === false ||
          //   (inputValues?.windowsLicense !== undefined &&
          //     inputValues?.windowsLicense !== "")) &&
          // inputValues?.ipAddress !== undefined &&
          // inputValues?.ipAddress !== ""
          // inputValues?.macId !== undefined &&
          // inputValues?.macId !== ""
        ) {
          if (currentAsset === "Laptop") {
            if (
              inputValues?.dimension !== undefined &&
              inputValues?.dimension !== "" &&
              inputValues?.dimension !== null
            ) {
              isFormFilled = true;
            }
          } else {
            isFormFilled = true;
          }
        }
      } else if (currentAsset === "Network" || currentAsset === "OtherAsset") {
        if (
          inputValues?.assetType !== undefined &&
          inputValues?.assetType !== "" &&
          inputValues.model !== undefined &&
          inputValues.model !== ""
        ) {
          if (currentAsset === "Network") {
            if (
              inputValues?.ram !== undefined &&
              inputValues?.ram !== "" &&
              inputValues.diskName !== undefined &&
              inputValues.diskName !== "" &&
              inputValues.networkPort !== undefined &&
              inputValues.networkPort !== "" &&
              // inputValues.imei !== undefined &&
              // inputValues.imei !== "" &&
              inputValues.location !== undefined &&
              inputValues.location !== "" &&
              inputValues.os !== undefined &&
              inputValues.os !== ""
              // inputValues?.assetType === "Server" &&
              // inputValues.processor !== undefined &&
              // inputValues.processor !== ""
            ) {
              isFormFilled = true;
            }

            if (inputValues?.assetType === "Server") {
              if (
                inputValues.processor !== undefined &&
                inputValues.processor !== ""
              ) {
                isFormFilled = true;
              } else {
                isFormFilled = false;
              }
            }
          } else if (currentAsset === "OtherAsset") {
            if (
              inputValues.wiredStatus !== undefined &&
              inputValues.wiredStatus !== ""
              // inputValues?.assetType === "Mobile" &&
              // inputValues.imei !== undefined &&
              // inputValues.imei !== ""
            ) {
              isFormFilled = true;
            }
            if (inputValues?.assetType === "Mobile") {
              if (inputValues.imei !== undefined && inputValues.imei !== "") {
                isFormFilled = true;
              } else {
                isFormFilled = false;
              }
            }
          }
        }
      } else if (currentAsset === "Monitor") {
        if (
          inputValues.screensize !== undefined &&
          inputValues.screensize !== ""
        ) {
          isFormFilled = true;
        }
      }
      if (inputValues?.purchaseDate > inputValues?.warrantyTill) {
        isFormFilled = false;
        setDataError((prevDataError) => ({
          ...prevDataError,
          purchaseDate: true,
          warrantyTill: true,
        }));
        toast.error("PurchaseDate can't be greater than warranty till", {
          toastId: customId,
        });
      }
    }

    if (isFormFilled === true) {
      if (
        sidebarAction === "edit" &&
        inputValues.isAssigned === true &&
        inputValues.isActive === false
      ) {
        toast.error("Please Unassign user", {
          toastId: customId,
        });
        setDataError((prevDataError) => ({
          ...prevDataError,
          isActive: true,
        }));
      } else if (sidebarAction === "add") {
        try {
          axiosInstance
            .post(
              AppSettings.URLs.asset +
                currentAsset +
                "/Add" +
                currentAsset +
                "DataAsync",
              inputValues
            )
            .then(() => {
              handleSidebarClose();
              setInputValues({});
              fetchData(currentAsset);
              setSearchFilter("");
              toast.success("Submitted Successfully");
            })
            .catch((error) => {
              console.log(error);
              toast.error("Something went wrong", {
                toastId: customId,
              });
            });
        } catch (error) {
          console.log(error);
          toast.error("Something went wrong", {
            toastId: customId,
          });
        }
      } else if (sidebarAction === "edit") {
        try {
          axiosInstance
            .put(
              AppSettings.URLs.asset +
                currentAsset +
                "/Update" +
                currentAsset +
                "DetailAsync?id=" +
                editItemId,
              inputValues
            )
            .then(() => {
              handleSidebarClose();
              setInputValues({});
              fetchData(currentAsset);
              toast.success("Updated Successfully", {
                toastId: customId,
              });
            })
            .catch((error) => {
              console.log(error);
              toast.error("Something went wrong", {
                toastId: customId,
              });
            });
        } catch (error) {
          console.log(error);
          toast.error("Something went wrong", {
            toastId: customId,
          });
        }
      }
    } else {
      console.log(dataError);
      toast.error("Please fill all the required fields", {
        toastId: customId,
      });
    }
  };

  const renderTooltip = (props) => (
    <Tooltip id="tooltip" {...props}>
      {props.tooltiptext}
    </Tooltip>
  );

  const deleteAsset = (data) => {
    confirmAlert({
      title: "Delete Asset",
      message: `Are you sure delete this asset: ${data.systemId}`,
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            try {
              axiosInstance
                .delete(
                  AppSettings.URLs.asset +
                    currentAsset +
                    "/Delete" +
                    currentAsset +
                    "DataAsync?id=" +
                    data.id
                )
                .then(() => {
                  fetchData(currentAsset);
                  handleAssetClick(currentAsset);
                  toast.success("Asset deleted successfully", {
                    toastId: customId,
                  });
                });
            } catch (error) {
              toast.error("Something went wrong", {
                toastId: customId,
              });
            }
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  function MyVerticallyCenteredModal(props) {
    return (
      <Modal
        {...props}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="border-0 pt-6 px-6 pb-2">
          <h2 className="font-bold color-dark mb-0 text-[14px] uppercase">
            Please add {currentAsset === "OtherAsset" ? "Other" : "Network"}{" "}
            asset type
          </h2>
        </Modal.Header>
        <Modal.Body className="py-0 px-6">
          <div className="row mt-2">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="form-group mb-2">
                <label className="kr-form-label">Add type</label>
                <input
                  type="text"
                  placeholder={`${
                    currentAsset === "OtherAsset" ? "Other" : "Network"
                  } asset type`}
                  className="form-control kr-form-textbox"
                  onChange={handlePopupInput}
                ></input>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-start bg-white py-3 px-6 mt-3">
          <Button className="btn btn-secondary" onClick={props.onHide}>
            Close
          </Button>
          <Button
            className="btn btn-Primary"
            onClick={() => {
              addDropDown();
            }}
          >
            Add
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  const showModal = async (event) => {
    await setAddProp(event);
    setModalShow(true);
  };

  let temp;
  const handlePopupInput = async (event) => {
    // event.preventDefault()
    temp = event?.target?.value;
    // await setPopUpInput(event.target.value);
  };

  const addDropDown = () => {
    let isFormFilled = false;
    if (temp?.length > 0) {
      isFormFilled = true;
    }
    if (isFormFilled === true) {
      try {
        const addQuery = `${
          currentAsset === "OtherAsset"
            ? "CreateOtherAssetTypeMaster"
            : "CreateNetworkTypeMaster"
        }?addType=${temp}`;
        axiosInstance
          .post(AppSettings.URLs.asset + currentAsset + "/" + addQuery)
          .then(() => {
            if (currentAsset === "Network") {
              fetchDropdownOptions("Network/GetAllNetworkAssetTypeMaster");
            } else if (currentAsset === "OtherAsset") {
              fetchDropdownOptions("OtherAsset/GetAllOtherAssetTypeMaster");
            }
            setModalShow(false);
            setSearchFilter("");
            toast.success("Submitted Successfully", {
              toastId: customId,
            });
          })
          .catch((error) => {
            toast.error("Something went wrong", {
              toastId: customId,
            });
          });
      } catch (error) {
        toast.error("Something went wrong", {
          toastId: customId,
        });
      }
    } else {
      toast.error("Input field cannot be empty", {
        toastId: customId,
      });
    }
  };
  return (
    <>
      <div className="page-titles bg-white px-6 ">
        <div className="flex items-center">
          <div className="mr-4 kr-page-back-btn">
            <NavLink to="/master" className="no-underline">
              <button
                type="button"
                className="btn-primary rounded flex items-center justify-center w-8"
              >
                <svg
                  width={18}
                  className="svg-white"
                  x="0px"
                  y="0px"
                  viewBox="0 0 700 700"
                >
                  <g>
                    <path
                      d="M82.9,331.2l148.3,148.3c7.6,7.6,18.6,10.6,29,7.8c10.4-2.8,18.5-10.9,21.2-21.2c2.8-10.4-0.2-21.4-7.8-29l-97.6-96.9
      h304.7c15.8,0,31,6.3,42.1,17.5c11.2,11.2,17.5,26.3,17.5,42.1v79c0,10.6,5.7,20.5,14.9,25.8c9.2,5.3,20.6,5.3,29.8,0
      c9.2-5.3,14.9-15.2,14.9-25.8v-79c0-31.6-12.6-61.9-34.9-84.3c-22.4-22.4-52.7-34.9-84.3-34.9H176.1l97.6-97.6
      c7.6-7.6,10.6-18.6,7.8-29c-2.8-10.4-10.9-18.5-21.2-21.2c-10.4-2.8-21.4,0.2-29,7.8L82.9,288.7c-5.7,5.6-8.9,13.3-8.9,21.2
      S77.2,325.6,82.9,331.2L82.9,331.2z"
                    ></path>
                  </g>
                </svg>
              </button>
            </NavLink>
          </div>
          <div>
            <h2 className="font-bold color-dark text-[14px] mb-0">
              Asset Management
            </h2>
            <span className="text-gray-400 text-[12px]">
              View all the Listed items
            </span>
          </div>
        </div>
      </div>
      <div className="pageBody-inner px-0 pt-0">
        <div className="kr-block">
          <div className="tabs">
            <div className="tab-button-outer">
              <div id="tab-button">
                <li className={`${currentAsset === "CPU" ? "is-active" : ""}`}>
                  <div
                    className="kr-asset-tab"
                    onClick={() => {
                      handleAssetClick("CPU");
                    }}
                  >
                    <div
                      className={`kr-emp-tab  ${
                        currentAsset === "CPU"
                          ? "kr-emp-tab-active border-danger border-top-0 border-end-0 border-start-0 border-2 text-dark"
                          : ""
                      }`}
                    >
                      Desktop
                    </div>
                  </div>
                </li>

                <li
                  className={`${currentAsset === "Laptop" ? "is-active" : ""}`}
                >
                  <div
                    className="kr-asset-tab"
                    onClick={() => {
                      handleAssetClick("Laptop");
                    }}
                  >
                    <div
                      className={`kr-emp-tab  ${
                        currentAsset === "Laptop"
                          ? "kr-emp-tab-active border-danger border-top-0 border-end-0 border-start-0 border-2 text-dark"
                          : ""
                      }`}
                    >
                      LAPTOP
                    </div>
                  </div>
                </li>

                <li
                  className={`${currentAsset === "Monitor" ? "is-active" : ""}`}
                >
                  <div
                    className="kr-asset-tab"
                    onClick={() => handleAssetClick("Monitor")}
                  >
                    <div
                      className={`kr-emp-tab  ${
                        currentAsset === "Monitor"
                          ? "kr-emp-tab-active border-danger border-top-0 border-end-0 border-start-0 border-2 text-dark"
                          : ""
                      }`}
                    >
                      MONITOR
                    </div>
                  </div>
                </li>

                <li
                  className={`${currentAsset === "Network" ? "is-active" : ""}`}
                >
                  <div
                    className="kr-asset-tab"
                    onClick={() => handleAssetClick("Network")}
                  >
                    <div
                      className={`kr-emp-tab  ${
                        currentAsset === "Network"
                          ? "kr-emp-tab-active border-danger border-top-0 border-end-0 border-start-0 border-2 text-dark"
                          : ""
                      }`}
                    >
                      Network
                    </div>
                  </div>
                </li>

                <li
                  className={`${
                    currentAsset === "OtherAsset" ? "is-active" : ""
                  }`}
                >
                  <div
                    className="kr-asset-tab"
                    onClick={() => handleAssetClick("OtherAsset")}
                  >
                    <div
                      className={`kr-emp-tab  ${
                        currentAsset === "OtherAsset"
                          ? "kr-emp-tab-active border-danger border-top-0 border-end-0 border-start-0 border-2 text-dark"
                          : ""
                      }`}
                    >
                      Other Asset
                    </div>
                  </div>
                </li>
              </div>
            </div>
            <div className="p-6 pb-0 flex gap-2 uppercase font-semibold text-[10px]">
              <span className="p-3 shadow-sm border-[#DD870B] bg-gradient-to-r to-[#ffffff] from-[#FFEACC] rounded-lg w-28">
                Total: {data?.length}
              </span>
              <span className="p-3 shadow-sm border-[#00C396] bg-gradient-to-r to-[#ffffff] from-[#CCFFF3] rounded-lg w-28">
                Active: {data?.filter((item) => item.isActive === true).length}
              </span>
              <span className="p-3 shadow-sm border-[#e2bfff] bg-gradient-to-r to-[#ffffff] from-[#dab5fcc5] rounded-lg w-28">
                Inactive:{" "}
                {data?.filter((item) => item.isActive === false).length}
              </span>
              {(currentAsset.toLocaleLowerCase() === "cpu" ||
                currentAsset.toLocaleLowerCase() === "laptop") && (
                <>
                  <span className="p-3 shadow-sm border-[#028FF0] bg-gradient-to-r to-[#ffffff] from-[#A4DAFF] rounded-lg w-28">
                    i5:{" "}
                    {
                      data?.filter(
                        (item) =>
                          item.processor.trim().toLowerCase().includes("i5")
                      ).length
                    }
                  </span>
                  <span className="p-3 shadow-sm border-[#F94A4A] bg-gradient-to-r to-[#ffffff] from-[#FFCCCC] rounded-lg w-28">
                    i7:{" "}
                    {
                      data?.filter(
                        (item) =>
                          item.processor.trim().toLowerCase().includes("i7")
                      ).length
                    }
                  </span>
                </>
              )}
            </div>
            <div className="pageBody-inner p-6">
              <div className="kr-block">
                <div className="card !border-none">
                  <div className="card-inner p-0">
                    <div className="kr-table-header border-bottom px-6 py-3">
                      <div className="w-100 flex items-center mb-lg-0 -md-0 justify-between">
                        {/* <div className="kr-table-filter">
                          <div className="dropdown">
                            <button
                              type="button"
                              className="flex items-center p-2 px-3 btn btn-light border border-solid  border-gray-200 btn"
                              aria-expanded="false"
                            >
                              <svg
                                width="12"
                                x="0px"
                                y="0px"
                                viewBox="0 0 700 700"
                              >
                                <g>
                                  <path
                                    d="M618.3,89.7H81.7c-16,0-30.7,8.5-38.7,22.4c-8,13.8-8,30.9,0,44.7c8,13.8,22.8,22.4,38.7,22.4h536.6
                                        c16,0,30.7-8.5,38.7-22.4c8-13.8,8-30.9,0-44.7C649,98.2,634.3,89.7,618.3,89.7z"
                                  ></path>
                                  <path
                                    d="M528.9,313.3H171.1c-16,0-30.7,8.5-38.7,22.4c-8,13.8-8,30.9,0,44.7c8,13.8,22.8,22.4,38.7,22.4h357.7
                                        c16,0,30.7-8.5,38.7-22.4c8-13.8,8-30.9,0-44.7C559.6,321.8,544.8,313.3,528.9,313.3L528.9,313.3z"
                                  ></path>
                                  <path
                                    d="M439.4,536.9H260.6c-16,0-30.7,8.5-38.7,22.4c-8,13.8-8,30.9,0,44.7c8,13.8,22.7,22.4,38.7,22.4h178.9
                                        c16,0,30.7-8.5,38.7-22.4c8-13.8,8-30.9,0-44.7C470.2,545.4,455.4,536.9,439.4,536.9z"
                                  ></path>
                                </g>
                              </svg>
                              <span className="pl-2">Filter</span>
                            </button>
                          </div>
                        </div> */}
                        <div className="flex gap-2 ">
                          <Select
                            searchable={false}
                            backspaceDelete={false}
                            placeholder="All"
                            name="wiredStatus"
                            values={[filterUser]}
                            className={`form-control kr-form-textbox !w-36 ${
                              dataError.wiredStatus === false ? "" : "dataError"
                            }`}
                            options={[
                              { value: "All", label: "All" },
                              { value: true, label: "Active" },
                              { value: false, label: "In-Active" },
                            ]}
                            onChange={(selectedOption) => {
                              setFilterUser({
                                value: selectedOption[0].value,
                                label: selectedOption[0].label,
                              });
                              // handleFilterChange(selectedOption[0].value);
                              handleSearch(
                                searchFilter,
                                selectedOption[0].value
                              );
                            }}
                          />
                        </div>
                        {/* wrapper of export and create */}
                        <div className=" flex gap-x-2">
                          {/* <CSVLink data={data} filename={`${currentAsset}'s Data`}>
                          <button className=" btn btn-export flex justify-center items-center min-w-80  cursor-pointer whitespace-nowrap ">Export data</button>
                        </CSVLink> */}

                          <div className="flex place-content-center user-search-filter-parent h-[32px]">
                            <input
                              onChange={(e) => {
                                handleSearch(e.target.value, filterUser.value);
                                setSearchFilter(e.target.value);
                              }}
                              type="text"
                              value={searchFilter}
                              placeholder="Search..."
                              className="user-search-filter-input"
                            />
                          </div>
                          <ExcelExportButton
                            data={filteredData}
                            asset={currentAsset}
                          />

                          <div className="ms-auto lg-flex md-flex block kr-table-header-action">
                            {currentAsset != null && (
                              // <Link to="/add" state={{currentAsset}} className="no-underline">
                              <button
                                type="submit"
                                className="btn btn-primary flex justify-center items-center min-w-80  cursor-pointer"
                                onClick={() => handleSidebarOpen("add", null)}
                              >
                                Create
                              </button>
                              // </Link>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    {currentAsset === "Network" ||
                    currentAsset === "OtherAsset" ? (
                      <div className="kr-block px-3 pt-2 pb-3">
                        <div className="overflow-x-auto">
                          {loading ? (
                            <table className="table kr-table-striped">
                              <thead>
                                <tr>
                                  <th rowSpan="2" className="text-center">
                                    #
                                  </th>
                                  <th rowSpan="2">System Id</th>
                                  <th rowSpan="2">S.No(on box)</th>
                                  <th rowSpan="2">Asset Type</th>
                                  <th rowSpan="2">Model</th>
                                  {currentAsset !== "Network" ? (
                                    <>
                                      <th rowSpan="2">Assigned To</th>
                                      <th rowSpan="2">Assigned To Prev</th>
                                    </>
                                  ) : null}
                                  {currentAsset === "OtherAsset" ? (
                                    <th rowSpan="2">Assign User</th>
                                  ) : null}
                                </tr>
                              </thead>

                              <tbody>
                                {displayedData?.map((data, index) => (
                                  <tr key={index} className={`relative `}>
                                    <td className="whitespace-nowrap text-center">
                                      {startIndex + index + 1}
                                    </td>
                                    <td className="uppercase">
                                      {data.systemId}
                                    </td>
                                    <td className="uppercase">
                                      {data.serialNo}
                                    </td>
                                    <td className="uppercase">
                                      {data?.assetType}
                                    </td>
                                    <td className="uppercase">{data.model}</td>

                                    {currentAsset !== "Network" ? (
                                      <>
                                        <td className="uppercase">
                                          {data.assignedTo?.length > 0
                                            ? data.assignedTo
                                            : "---"}
                                        </td>
                                        <td className="uppercase">
                                          {data.assignedToPrevious?.length > 0
                                            ? data.assignedToPrevious
                                            : "---"}
                                        </td>
                                      </>
                                    ) : null}

                                    {currentAsset === "OtherAsset" ? (
                                      <td>
                                        <AllcoateAsset
                                          data={data}
                                          asset={currentAsset}
                                          getAsset={fetchData}
                                        />
                                      </td>
                                    ) : null}

                                    {/* <td>
                                        <AllcoateAsset
                                          data={data}
                                          asset={currentAsset}
                                          getAsset={refreshData}
                                        />
                                      </td> */}
                                    <td className="kr-tbl-action">
                                      <div className="flex justify-end shrink-0">
                                        <ul className=" flex">
                                          {/* <OverlayTrigger key={data.id} placement="top" overlay={renderTooltip({ tooltiptext: "View Details" })}> */}
                                          {/* <Tooltip title="View Detail" placement="top" arrow > */}
                                          <li>
                                            <History
                                              data={data}
                                              asset={currentAsset}
                                            ></History>
                                          </li>

                                          <li>
                                            <ViewDetail
                                              data={data}
                                              asset={currentAsset}
                                            />
                                          </li>
                                          {/* </Tooltip> */}
                                          {/* </OverlayTrigger> */}
                                          <li>
                                            {/* <OverlayTrigger key={data.id} placement="top" overlay={renderTooltip({ tooltiptext: "Edit" })}> */}
                                            <Tooltip
                                              title="Edit"
                                              placement="top"
                                              arrow
                                              leaveDelay={50}
                                            >
                                              <div
                                                className="kr-tbl-edit cursor-pointer action-item"
                                                onClick={() => {
                                                  handleSidebarOpen(
                                                    "edit",
                                                    data
                                                  );
                                                }}
                                              >
                                                <span className="svg-icon">
                                                  <svg
                                                    x="0px"
                                                    y="0px"
                                                    viewBox="0 0 700 700"
                                                  >
                                                    <g>
                                                      <path
                                                        d="M631.3,133.8l-80-80C539.9,42.5,524.6,36,508.4,36c-16.2,0-31.5,6.5-42.8,17.8L117.1,402.2c-7.3,7.3-12.1,16.2-15.4,25.9
                                     l-49.3,169c-3.2,9.7,0,20.2,7.3,28.3c5.7,5.7,12.9,8.1,20.2,8.1c2.4,0,5.7,0,8.1-0.8l169-49.3c9.7-3.2,18.6-8.1,25.9-15.4
                                     l348.4-348.4c11.3-11.3,17.8-26.7,17.8-42.8C649.1,160.5,642.6,145.1,631.3,133.8L631.3,133.8z M147,475l63.1,63.1l-88.9,25.9
                                     L147,475z M590.9,179.9l-55,55l-85.7-85.7l55-55c0.8-0.8,2.4-0.8,3.2-0.8c0.8,0,1.6,0,3.2,0.8l80,80c0.8,0.8,0.8,2.4,0.8,3.2
                                     C592.5,178.3,591.7,179.1,590.9,179.9z"
                                                      ></path>
                                                    </g>
                                                  </svg>
                                                </span>
                                              </div>
                                            </Tooltip>
                                            {/* </OverlayTrigger> */}
                                          </li>
                                          <li>
                                            {/* <OverlayTrigger key={data.id} placement="top" overlay={renderTooltip({ tooltiptext: "Delete" })}> */}
                                            <Tooltip
                                              title="Delete"
                                              placement="top"
                                              arrow
                                              leaveDelay={50}
                                            >
                                              <div
                                                className="kr-tbl-delete cursor-pointer action-item"
                                                href="#"
                                                onClick={() => {
                                                  deleteAsset(data);
                                                }}
                                              >
                                                <span className="svg-icon">
                                                  <svg
                                                    x="0px"
                                                    y="0px"
                                                    viewBox="0 0 700 700"
                                                  >
                                                    <g>
                                                      <path
                                                        d="M598.3,144.5H475.1v-26.9c0-19-17.4-33-39.2-33H297.6c-21.8,0-39.2,14.6-39.2,33v26.9H135.7c-12.3,0-21.8,9.5-21.8,21.8
                                           c0,12.3,9.5,21.8,21.8,21.8h26.9l26.9,336.6c0.6,39.8,32.5,71.7,72.2,71.7h210c39.8,0,71.7-31.9,72.2-71.7l27.4-336h26.9
                                           c12.3,0,21.8-9.5,21.8-21.8C620.1,154.6,610.6,144.5,598.3,144.5L598.3,144.5z M302,127.7H432v16.8l-129.9,0V127.7z M310.4,479.4
                                           h-0.6c-11.8,0-20.7-9.5-21.8-20.7l-7.8-176.4c-0.6-12.3,8.4-22.4,20.7-22.4h0.6c11.8,0,20.7,9.5,21.8,20.7l7.8,176.4
                                           C331.7,468.7,322.8,478.8,310.4,479.4L310.4,479.4z M446,458.1c-0.6,11.8-10.1,20.7-21.8,20.7h-0.6c-12.3-0.6-20.7-10.6-20.7-22.4
                                           l7.8-176.4c0.6-11.8,10.1-20.7,21.8-20.7h0.6c12.3,0.6,20.7,10.6,20.7,22.4L446,458.1z"
                                                      ></path>
                                                    </g>
                                                  </svg>
                                                </span>
                                              </div>
                                            </Tooltip>
                                            {/* </OverlayTrigger> */}
                                          </li>
                                        </ul>
                                      </div>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          ) : (
                            <div className="w-100 flex justify-center items-center py-6 bg-light">
                              <div className="kr-pageLoader">
                                <span className="loader"></span>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    ) : (
                      <div className="kr-block px-3 pt-2 pb-3">
                        <div className="overflow-x-auto">
                          {loading ? (
                            <table className="table kr-table-striped">
                              <thead>
                                <tr>
                                  <th rowSpan="2" className="text-center">
                                    #
                                  </th>
                                  <th rowSpan="2">System ID</th>
                                  <th rowSpan="2">S.no(on box)</th>

                                  {/* For Laptop and CPU */}

                                  <th rowSpan="2">Brand</th>
                                  {(currentAsset === "Laptop" ||
                                    currentAsset === "CPU") && (
                                    <th rowSpan="2">Processor</th>
                                  )}
                                  {(currentAsset === "Laptop" ||
                                    currentAsset === "CPU") && (
                                    <th rowSpan="2">RAM</th>
                                  )}

                                  <th
                                    rowSpan="2"
                                    className={`${
                                      currentAsset === "Monitor" ? "" : "hidden"
                                    }`}
                                  >
                                    Screen size
                                  </th>

                                  {/* <th rowSpan="2">Is Assigned</th> */}
                                  <th rowSpan="2">Assigned To</th>
                                  <th rowSpan="2">Assigned To Prev</th>
                                  <th rowSpan="2">No.of Months</th>

                                  {/* <th rowSpan="2">State</th> */}
                                  <th rowSpan="2">Assign User</th>

                                  {/* For Monitor */}

                                  {/* For Webcam */}
                                </tr>
                              </thead>
                              <tbody>
                                {displayedData.map((data, index) => (
                                  <tr key={index} className="relative">
                                    <td className="whitespace-nowrap text-center">
                                      {startIndex + index + 1}
                                    </td>
                                    <td className="uppercase">
                                      {data.systemId}
                                    </td>
                                    <td className="uppercase">
                                      {data.serialNo}
                                    </td>
                                    {/* <td className="uppercase">{data.modelNo}</td> */}

                                    <td className="uppercase">{data.brand}</td>
                                    {(currentAsset === "Laptop" ||
                                      currentAsset === "CPU") && (
                                      <td className="uppercase">
                                        {data?.processor?.length > 18
                                          ? data?.processor?.substr(0, 18) +
                                            ".."
                                          : data?.processor}
                                      </td>
                                    )}
                                    {(currentAsset === "Laptop" ||
                                      currentAsset === "CPU") && (
                                      <td className="uppercase">{data?.ram}</td>
                                    )}

                                    <td
                                      className={`uppercase ${
                                        currentAsset === "Monitor"
                                          ? ""
                                          : "hidden"
                                      }`}
                                    >
                                      {data.screensize}
                                    </td>

                                    {/* For Laptop and CPU */}

                                    {/* <td className="uppercase" >{data.isAssigned ? "yes" : "no"}</td> */}

                                    <td className="uppercase">
                                      {data.assignedTo?.length > 0
                                        ? data.assignedTo
                                        : "---"}
                                    </td>

                                    <td className="uppercase">
                                      {data.assignedToPrevious?.length > 0
                                        ? data.assignedToPrevious?.split(" ")[0]
                                        : "---"}
                                    </td>

                                    <td className="uppercase">
                                      {data.noOfMonthsOld}
                                    </td>

                                    {/* For Monitor */}

                                    <td>
                                      <AllcoateAsset
                                        data={data}
                                        asset={currentAsset}
                                        getAsset={refreshData}
                                      />
                                    </td>
                                    <td className="kr-tbl-action">
                                      <div className="flex justify-end shrink-0">
                                        <ul className=" flex">
                                          {/* <OverlayTrigger key={data.id} placement="top" overlay={renderTooltip({ tooltiptext: "View Details" })}> */}
                                          {/* <Tooltip title="View Detail" placement="top" arrow > */}
                                          <li>
                                            <History
                                              data={data}
                                              asset={currentAsset}
                                            ></History>
                                          </li>

                                          <li>
                                            <ViewDetail
                                              data={data}
                                              asset={currentAsset}
                                            />
                                          </li>
                                          {/* </Tooltip> */}
                                          {/* </OverlayTrigger> */}
                                          <li>
                                            {/* <OverlayTrigger key={data.id} placement="top" overlay={renderTooltip({ tooltiptext: "Edit" })}> */}
                                            <Tooltip
                                              title="Edit"
                                              placement="top"
                                              arrow
                                              leaveDelay={50}
                                            >
                                              <div
                                                className="kr-tbl-edit cursor-pointer action-item"
                                                onClick={() => {
                                                  handleSidebarOpen(
                                                    "edit",
                                                    data
                                                  );
                                                }}
                                              >
                                                <span className="svg-icon">
                                                  <svg
                                                    x="0px"
                                                    y="0px"
                                                    viewBox="0 0 700 700"
                                                  >
                                                    <g>
                                                      <path
                                                        d="M631.3,133.8l-80-80C539.9,42.5,524.6,36,508.4,36c-16.2,0-31.5,6.5-42.8,17.8L117.1,402.2c-7.3,7.3-12.1,16.2-15.4,25.9
                                     l-49.3,169c-3.2,9.7,0,20.2,7.3,28.3c5.7,5.7,12.9,8.1,20.2,8.1c2.4,0,5.7,0,8.1-0.8l169-49.3c9.7-3.2,18.6-8.1,25.9-15.4
                                     l348.4-348.4c11.3-11.3,17.8-26.7,17.8-42.8C649.1,160.5,642.6,145.1,631.3,133.8L631.3,133.8z M147,475l63.1,63.1l-88.9,25.9
                                     L147,475z M590.9,179.9l-55,55l-85.7-85.7l55-55c0.8-0.8,2.4-0.8,3.2-0.8c0.8,0,1.6,0,3.2,0.8l80,80c0.8,0.8,0.8,2.4,0.8,3.2
                                     C592.5,178.3,591.7,179.1,590.9,179.9z"
                                                      ></path>
                                                    </g>
                                                  </svg>
                                                </span>
                                              </div>
                                            </Tooltip>
                                            {/* </OverlayTrigger> */}
                                          </li>
                                          <li>
                                            {/* <OverlayTrigger key={data.id} placement="top" overlay={renderTooltip({ tooltiptext: "Delete" })}> */}
                                            <Tooltip
                                              title="Delete"
                                              placement="top"
                                              arrow
                                              leaveDelay={50}
                                            >
                                              <div
                                                className="kr-tbl-delete cursor-pointer action-item"
                                                href="#"
                                                onClick={() => {
                                                  deleteAsset(data);
                                                }}
                                              >
                                                <span className="svg-icon">
                                                  <svg
                                                    x="0px"
                                                    y="0px"
                                                    viewBox="0 0 700 700"
                                                  >
                                                    <g>
                                                      <path
                                                        d="M598.3,144.5H475.1v-26.9c0-19-17.4-33-39.2-33H297.6c-21.8,0-39.2,14.6-39.2,33v26.9H135.7c-12.3,0-21.8,9.5-21.8,21.8
                                           c0,12.3,9.5,21.8,21.8,21.8h26.9l26.9,336.6c0.6,39.8,32.5,71.7,72.2,71.7h210c39.8,0,71.7-31.9,72.2-71.7l27.4-336h26.9
                                           c12.3,0,21.8-9.5,21.8-21.8C620.1,154.6,610.6,144.5,598.3,144.5L598.3,144.5z M302,127.7H432v16.8l-129.9,0V127.7z M310.4,479.4
                                           h-0.6c-11.8,0-20.7-9.5-21.8-20.7l-7.8-176.4c-0.6-12.3,8.4-22.4,20.7-22.4h0.6c11.8,0,20.7,9.5,21.8,20.7l7.8,176.4
                                           C331.7,468.7,322.8,478.8,310.4,479.4L310.4,479.4z M446,458.1c-0.6,11.8-10.1,20.7-21.8,20.7h-0.6c-12.3-0.6-20.7-10.6-20.7-22.4
                                           l7.8-176.4c0.6-11.8,10.1-20.7,21.8-20.7h0.6c12.3,0.6,20.7,10.6,20.7,22.4L446,458.1z"
                                                      ></path>
                                                    </g>
                                                  </svg>
                                                </span>
                                              </div>
                                            </Tooltip>
                                            {/* </OverlayTrigger> */}
                                          </li>
                                        </ul>
                                      </div>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          ) : (
                            <div className="w-100 flex justify-center items-center py-6 bg-light">
                              <div className="kr-pageLoader">
                                <span className="loader"></span>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    )}

                    <div className="kr-block pt-3 pb-3 font-size-12px color-gray border-top px-3">
                      <nav className="w-full position:relative m-0">
                        <ul className="list-none ml-0 mb-0 pagination">
                          <li
                            className={`inline-block pagination-prev cursor-pointer ${
                              currentPage === 1 ? "disabled" : ""
                            }`}
                            onClick={goToPreviousPage}
                          >
                            <span>Previous</span>
                          </li>
                          {renderPageNumbers()}
                          <li
                            className={`inline-block pagination-next cursor-pointer ${
                              endIndex >= data?.length ? "disabled" : ""
                            }`}
                            onClick={goToNextPage}
                          >
                            <span>Next</span>
                          </li>
                        </ul>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Add Asset Side Menu */}
      <div
        className={`kr-popup-overlay ${
          isSidebarOpen ? "kr-side-popup-overlay" : ""
        }`}
      ></div>
      <div
        className={`kr-side-popup ${
          isSidebarOpen ? "kr-side-popup-on md:!w-[600px] !w-[90vw]" : ""
        }`}
      >
        <div className="kr-block w-100">
          <div className="flex items-center py-[16px] px-6 bg-gray-50">
            <div className="pl-2">
              <h2 className="font-bold color-dark text-[14px] mb-0">
                {sidebarAction === "add" ? "Create Asset" : "Update Asset"}
              </h2>
              <span className="text-gray-400 text-[12px]">
                {sidebarAction === "add" ? "Create" : "Update"} your Asset below
              </span>
            </div>
          </div>
          <div className="kr-block p-4">
            <div id="form">
              {currentAsset === "Network" || currentAsset === "OtherAsset" ? (
                <div className="grid md:!grid-cols-2 gap-x-4 !grid-cols-1">
                  <div className="col-span-2 md:col-span-1">
                    <div className="kr-selectform required">
                      <label className="kr-form-label">
                        {currentAsset === "Network"
                          ? "Network Asset Type"
                          : "AssetType"}
                      </label>
                      <div className="kr-selectform flex">
                        <Select
                          searchable={false}
                          backspaceDelete={false}
                          placeholder="Network Asset Type"
                          name="assetType"
                          values={[selectedValue?.assetType]}
                          className={`form-control kr-form-textbox ${
                            dataError?.assetType === false ? "" : "dataError"
                          }`}
                          options={
                            currentAsset === "OtherAsset"
                              ? dropdownOptions?.other?.map((option) => ({
                                  value: option?.assetType,
                                  label: option?.assetType,
                                }))
                              : dropdownOptions?.network?.map((option) => ({
                                  value: option?.assetType,
                                  label: option?.assetType,
                                }))
                          }
                          onChange={(selectedOption) => {
                            if (sidebarAction === "add") {
                              setInputValues({});
                              setSelectedValue({
                                //network and other asset
                                assetType: {
                                  value: null,
                                  label: "Network Asset Type",
                                },
                                diskName: { value: null, label: "" },
                                networkPort: { value: null, label: "" },
                                imei: { value: null, label: "" },
                                location: { value: null, label: "" },
                                model: { value: null, label: "" },
                                wiredStatus: {
                                  value: null,
                                  label: "Wired status",
                                },
                                os: { value: null, label: "" },
                                //
                                serialNo: { value: null, label: "" },
                                systemId: { value: null, label: "" },
                                modelNo: { value: null, label: "" },
                                macId: { value: null, label: "" },
                                dimension: { value: null, label: "" },
                                biosVersion: { value: null, label: "" },
                                brand: { value: null, label: "" },
                                ram: { value: null, label: "" },
                                processor: { value: null, label: "" },
                                generation: { value: null, label: "" },
                                storageType: {
                                  value: null,
                                  label: "Storage Type",
                                },
                                windowsOS: { value: null, label: "OS Type" },
                                storageSize: { value: null, label: "" },
                                screensize: { value: null, label: "" },
                                purchaseDate: { value: null, label: "" },
                                isWarrantyTill: {
                                  value: null,
                                  label: "warranty",
                                },
                                warrantyTill: {
                                  value: null,
                                  label: "warranty",
                                },
                                // isActive: { value: null, label: "Is Active" },
                                // createdBy: { value: null, label: "" },
                                createdOn: { value: null, label: "" },
                                // isMonitor: { value: null, label: "is Monitor" },
                                isKeyboard: { value: null, label: "Keyboard" },
                                isMouse: { value: null, label: "Mouse" },
                                isHeadSet: { value: null, label: "HeadSet" },
                                isAVInstalled: {
                                  value: null,
                                  label: "AVInstalled",
                                },
                                isLicensed: {
                                  value: null,
                                  label:
                                    inputValues.windowsOS === "Mac Os" ||
                                    inputValues.windowsOS === "Windows" ||
                                    inputValues.windowsOS === undefined ||
                                    inputValues.windowsOS === null ||
                                    ""
                                      ? "Licensed/Not"
                                      : "License Type",
                                },
                                windowsLicense: { value: null, label: "" },
                                isActive: { value: null, label: "State" },
                                remarks: { value: null, label: "" },
                                ipAddress: { value: null, label: "" },
                              });
                            }
                            handleInputChange(selectedOption, "assetType");
                          }}
                          disabled={sidebarAction === "edit"}
                        />
                        <button
                          type="button"
                          className=" btn-primary rounded !rounded-tl-none !rounded-bl-none flex items-center justify-center w-[46px]"
                          onClick={() => showModal()}
                        >
                          <svg
                            width={10}
                            className="svg-white"
                            x="0px"
                            y="0px"
                            viewBox="0 0 34.81 34.81"
                          >
                            <g>
                              <path d="M17.41,0C16.14,0,15.12,1.03,15.12,2.29V15.12H2.29c-1.26,0-2.29,1.03-2.29,2.29s1.03,2.29,2.29,2.29H15.12v12.83c0,1.26,1.03,2.29,2.29,2.29s2.29-1.03,2.29-2.29v-12.83h12.83c1.26,0,2.29-1.03,2.29-2.29s-1.03-2.29-2.29-2.29h-12.83V2.29c0-1.26-1.03-2.29-2.29-2.29h0Z"></path>
                            </g>
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="col-span-2 md:col-span-1">
                    <div className="kr-selectform required">
                      <label className="kr-form-label">Model </label>
                      <input
                        type="text"
                        placeholder="Model"
                        value={selectedValue?.model?.label}
                        className={`form-control kr-form-textbox placeholder-[#212529] ${
                          dataError?.model === false ? "" : "dataError"
                        }`}
                        onChange={(e) => handleInputChange(e, "model")}
                      ></input>
                    </div>
                  </div>

                  <div className="col-span-2 md:col-span-1">
                    <div className="kr-selectform required">
                      <label className="kr-form-label">System ID </label>
                      <input
                        type="number"
                        placeholder="System ID"
                        max="4"
                        value={selectedValue?.systemId?.label}
                        className={`form-control kr-form-textbox placeholder-[#212529] ${
                          dataError?.systemId === false ? "" : "dataError"
                        }`}
                        onChange={(e) => {
                          if (e.target.value.length > 4) {
                            e.target.value = e.target.value.slice(0, 4);
                          }
                          handleInputChange(e, "systemId");
                        }}
                      ></input>
                    </div>
                  </div>

                  <div className="col-span-2 md:col-span-1">
                    <div className="kr-selectform required">
                      <label className="kr-form-label">Serial Number </label>
                      <input
                        type="text"
                        placeholder="Serial Number"
                        value={selectedValue?.serialNo?.label}
                        className={`form-control kr-form-textbox placeholder-[#212529] ${
                          dataError?.serialNo === false ? "" : "dataError"
                        }`}
                        onChange={(e) => handleInputChange(e, "serialNo")}
                      ></input>
                    </div>
                  </div>

                  <div
                    className={` col-span-2 md:col-span-1 ${
                      currentAsset === "Network" ? "" : "hidden"
                    }`}
                  >
                    <div className="kr-selectform required">
                      <label className="kr-form-label">RAM </label>
                      <input
                        type="text"
                        placeholder="RAM"
                        value={selectedValue?.ram?.label}
                        className={`form-control kr-form-textbox placeholder-[#212529] ${
                          dataError?.ram === false ? "" : "dataError"
                        }`}
                        onChange={(e) => handleInputChange(e, "ram")}
                      ></input>
                    </div>
                  </div>

                  <div
                    className={` col-span-2 md:col-span-1 ${
                      currentAsset === "OtherAsset" &&
                      inputValues?.assetType === "Mobile"
                        ? ""
                        : "hidden"
                    }`}
                  >
                    <div className="kr-selectform required">
                      <label className="kr-form-label">IMEI </label>
                      <input
                        type="text"
                        placeholder="Imei"
                        value={selectedValue?.imei?.label}
                        className={`form-control kr-form-textbox placeholder-[#212529] ${
                          dataError?.imei === false ? "" : "dataError"
                        }`}
                        onChange={(e) => handleInputChange(e, "imei")}
                      ></input>
                    </div>
                  </div>

                  <div
                    className={` col-span-2 md:col-span-1 ${
                      currentAsset === "OtherAsset" ? "" : "hidden"
                    }`}
                  >
                    <div className="kr-selectform required">
                      <label className="kr-form-label">Wired Status </label>
                      <Select
                        searchable={false}
                        backspaceDelete={false}
                        placeholder="Wired type"
                        name="wiredStatus"
                        values={[selectedValue?.wiredStatus]}
                        className={`form-control kr-form-textbox ${
                          dataError.wiredStatus === false ? "" : "dataError"
                        }`}
                        options={[
                          { value: "Wired", label: "Wired" },
                          { value: "Wireless", label: "wireless" },
                        ]}
                        onChange={(selectedOption) =>
                          handleInputChange(selectedOption, "wiredStatus")
                        }
                      />
                    </div>
                  </div>

                  <div
                    className={` col-span-2 md:col-span-1 ${
                      currentAsset === "Network" ? "" : "hidden"
                    }`}
                  >
                    <div className="kr-selectform required">
                      <label className="kr-form-label">Disk </label>
                      <input
                        type="text"
                        placeholder="Disk"
                        value={selectedValue?.diskName?.label}
                        className={`form-control kr-form-textbox placeholder-[#212529] ${
                          dataError?.diskName === false ? "" : "dataError"
                        }`}
                        onChange={(e) => handleInputChange(e, "diskName")}
                      ></input>
                    </div>
                  </div>

                  <div
                    className={` col-span-2 md:col-span-1 ${
                      currentAsset === "Network" ? "" : "hidden"
                    }`}
                  >
                    <div className="kr-selectform required">
                      <label className="kr-form-label">OS </label>
                      <input
                        type="text"
                        placeholder="OS"
                        value={selectedValue?.os?.label}
                        className={`form-control kr-form-textbox placeholder-[#212529] ${
                          dataError?.os === false ? "" : "dataError"
                        }`}
                        onChange={(e) => handleInputChange(e, "os")}
                      ></input>
                    </div>
                  </div>

                  {inputValues?.assetType === "Server" ? (
                    <div className="col-span-2 md:col-span-1">
                      <div className="kr-selectform required">
                        <label className="kr-form-label">Processor</label>
                        <input
                          type="text"
                          placeholder="Processor"
                          value={selectedValue?.processor?.label}
                          className={`form-control kr-form-textbox placeholder-[#212529] ${
                            dataError.processor === false ? "" : "dataError"
                          }`}
                          onChange={(e) => handleInputChange(e, "processor")}
                        ></input>
                      </div>
                    </div>
                  ) : null}

                  <div className="col-span-2 md:col-span-1">
                    <div className="kr-selectform">
                      <label className="kr-form-label">Purchase Date</label>
                      <input
                        min="2001-01"
                        max={`${new Date().toISOString().slice(0, 7)}`}
                        type="date"
                        placeholder="Purchase Date"
                        name="PurchaseDate"
                        value={selectedValue?.purchaseDate?.label}
                        className={`form-control kr-form-textbox placeholder-[#212529]
                         
                       `}
                        onChange={(e) => handleInputChange(e, "purchaseDate")}
                        // onKeyDown={(e) => e.preventDefault()}
                        for
                      ></input>
                    </div>
                  </div>

                  <div className="col-span-2 md:col-span-1">
                    <div className="kr-selectform required">
                      <label className="kr-form-label">Warranty</label>
                      <Select
                        searchable={false}
                        backspaceDelete={false}
                        placeholder="Warranty"
                        name="isWarrantyTill"
                        values={[selectedValue?.isWarrantyTill]}
                        className={`form-control kr-form-textbox ${
                          dataError?.isWarrantyTill === false ? "" : "dataError"
                        }`}
                        options={[
                          { value: true, label: "Active" },
                          { value: false, label: "In Active" },
                        ]}
                        onChange={(selectedOption) => {
                          handleInputChange(selectedOption, "isWarrantyTill");
                        }}
                      />
                    </div>
                  </div>

                  {inputValues?.isWarrantyTill === true ? (
                    <div className="col-span-2 md:col-span-1">
                      <div className="kr-selectform">
                        <label className="kr-form-label">Warranty Till</label>
                        <input
                          min="2001-01"
                          max={`${new Date().toISOString().slice(0, 7)}`}
                          type="date"
                          placeholder="Warranty Till"
                          name="Warrantytill"
                          value={selectedValue?.warrantyTill?.label}
                          className={`form-control kr-form-textbox placeholder-[#212529] 
                    
                      `}
                          onChange={(e) => handleInputChange(e, "warrantyTill")}
                          // onKeyDown={(e) => e.preventDefault()}
                        ></input>
                      </div>
                    </div>
                  ) : null}

                  <div className="col-span-2 md:col-span-1">
                    <div className="kr-selectform required">
                      <label className="kr-form-label">State</label>
                      <Select
                        searchable={false}
                        backspaceDelete={false}
                        placeholder="State"
                        name="State"
                        values={[selectedValue?.isActive]}
                        className={`form-control kr-form-textbox ${
                          dataError.isActive === false ? "" : "dataError"
                        }`}
                        options={[
                          { value: true, label: "Active" },
                          { value: false, label: "In Active" },
                        ]}
                        onChange={(selectedOption) =>
                          handleInputChange(selectedOption, "isActive")
                        }
                      />
                    </div>
                  </div>

                  <div
                    className={` col-span-2 md:col-span-1 ${
                      currentAsset === "Network" ? "" : "hidden"
                    }`}
                  >
                    <div className="kr-selectform required">
                      <label className="kr-form-label">Network Port </label>
                      <input
                        type="text"
                        placeholder="Network Port"
                        value={selectedValue?.networkPort?.label}
                        className={`form-control kr-form-textbox placeholder-[#212529] ${
                          dataError?.networkPort === false ? "" : "dataError"
                        }`}
                        onChange={(e) => handleInputChange(e, "networkPort")}
                      ></input>
                    </div>
                  </div>

                  <div
                    className={` col-span-2 md:col-span-1 ${
                      currentAsset === "Network" ? "" : "hidden"
                    }`}
                  >
                    {/* <div className="kr-selectform required">
                      <label className="kr-form-label">IMEI </label>
                      <input
                        type="text"
                        placeholder="Imei"
                        value={selectedValue?.imei?.label}
                        className={`form-control kr-form-textbox placeholder-[#212529] ${
                          dataError?.imei === false ? "" : "dataError"
                        }`}
                        onChange={(e) => handleInputChange(e, "imei")}
                      ></input>
                    </div> */}
                    <div className="kr-selectform required">
                      <label className="kr-form-label">Location </label>
                      <input
                        type="text"
                        placeholder="Location"
                        value={selectedValue?.location?.label}
                        className={`form-control kr-form-textbox placeholder-[#212529] ${
                          dataError?.location === false ? "" : "dataError"
                        }`}
                        onChange={(e) => handleInputChange(e, "location")}
                      ></input>
                    </div>
                  </div>

                  {/* <div className="col-span-2 md:col-span-1">
                    <div className="kr-selectform ">
                      <label className="kr-form-label">Created By</label>
                      <input
                        type="text"
                        placeholder="Created By"
                        name="Createdby"
                        value={selectedValue?.createdBy?.label}
                        className={`form-control kr-form-textbox placeholder-[#212529] 
                     
                      `}
                        onChange={(e) => handleInputChange(e, "createdBy")}
                        readOnly={sidebarAction === "edit"}
                      ></input>
                    </div>
                  </div> */}

                  {/* <div className="col-span-2 md:col-span-1">
                    <div className="kr-selectform ">
                      <label className="kr-form-label">Created On</label>
                      <input
                        type="text"
                        placeholder="Created On"
                        name="Createdon"
                        value={new Date().toISOString().substr(0, 10)}
                        className={`form-control kr-form-textbox placeholder-[#212529] 
                     
                      `}
                        onChange={(e) => handleInputChange(e, "createdOn")}
                        readOnly
                      ></input>
                    </div>
                  </div> */}

                  <div className="col-span-2 md:col-span-1">
                    <div className="kr-selectform ">
                      <label className="kr-form-label">Remarks</label>
                      <input
                        type="text"
                        placeholder="Remarks"
                        name="Remarks"
                        value={selectedValue?.remarks?.label}
                        className={`form-control kr-form-textbox placeholder-[#212529] 
                      
                      `}
                        onChange={(e) => handleInputChange(e, "remarks")}
                      ></input>
                    </div>
                  </div>

                  <div className="col-span-2">
                    <div className="kr-selectform flex justify-end">
                      {/* <Link to ='/' className='no-underline'> */}
                      <button
                        type="button"
                        className="flex items-center justify-center py-[6px] px-[12px] btn-light border border-solid border-gray-200 rounded min-w-80"
                        onClick={handleSidebarClose}
                      >
                        Cancel
                      </button>
                      {/* </Link> */}
                      {/* <Link to ='/' className='no-underline'> */}
                      <button
                        type="button"
                        className="btn btn-primary flex items-center justify-center ml-2 min-w-80"
                        onClick={handleSidebarFormSubmit}
                      >
                        {sidebarAction === "add" ? "Add" : "Update"}
                      </button>
                      {/* </Link> */}
                    </div>
                  </div>
                </div>
              ) : (
                <div className="grid md:!grid-cols-2 gap-x-4 !grid-cols-1">
                  <div className="col-span-2 md:col-span-1">
                    <div className="kr-selectform required">
                      <label className="kr-form-label">System ID </label>
                      <input
                        type="number"
                        placeholder="System ID"
                        max="4"
                        value={selectedValue?.systemId?.label}
                        className={`form-control kr-form-textbox placeholder-[#212529] ${
                          dataError?.systemId === false ? "" : "dataError"
                        }`}
                        onChange={(e) => {
                          if (e.target.value.length > 4) {
                            e.target.value = e.target.value.slice(0, 4);
                          }
                          handleInputChange(e, "systemId");
                        }}
                      ></input>
                    </div>
                  </div>

                  <div className="col-span-2 md:col-span-1">
                    <div className="kr-selectform required">
                      <label className="kr-form-label">S.No (On Box) </label>
                      <input
                        type="text"
                        placeholder="Serial No"
                        value={selectedValue?.serialNo?.label}
                        className={`form-control kr-form-textbox placeholder-[#212529] ${
                          dataError?.serialNo === false ? "" : "dataError"
                        }`}
                        onChange={(e) => handleInputChange(e, "serialNo")}
                      ></input>
                    </div>
                  </div>
                  <div className="col-span-2 md:col-span-1">
                    <div className="kr-selectform required">
                      <label className="kr-form-label">Brand</label>
                      <input
                        type="text"
                        placeholder="Brand"
                        value={selectedValue?.brand?.label}
                        className={`form-control kr-form-textbox placeholder-[#212529] ${
                          dataError.brand === false ? "" : "dataError"
                        }`}
                        onChange={(e) => handleInputChange(e, "brand")}
                      ></input>
                    </div>
                  </div>

                  <div className="col-span-2 md:col-span-1">
                    <div className="kr-selectform required">
                      <label className="kr-form-label">System Model</label>
                      <input
                        type="text"
                        placeholder="System Model"
                        value={selectedValue?.modelNo?.label}
                        className={`form-control kr-form-textbox placeholder-[#212529] ${
                          dataError?.modelNo === false ? "" : "dataError"
                        }`}
                        onChange={(e) => handleInputChange(e, "modelNo")}
                      ></input>
                    </div>
                  </div>

                  {/* for monitor only  */}
                  {currentAsset === "CPU" || currentAsset === "Laptop" ? (
                    ""
                  ) : (
                    <div className="col-span-2 md:col-span-1">
                      <div className="kr-selectform required">
                        <label className="kr-form-label "> Screen Size</label>
                        <input
                          type="text"
                          placeholder="Screen Size"
                          value={selectedValue?.screensize?.label}
                          name="screensize"
                          className={`form-control kr-form-textbox placeholder-[#212529] ${
                            dataError?.screensize === false ? "" : "dataError"
                          }`}
                          onChange={(e) => handleInputChange(e, "screensize")}
                        ></input>
                      </div>
                    </div>
                  )}

                  {/* For CPU and Laptop */}
                  <div
                    className={`col-span-2 md:col-span-1 ${
                      currentAsset === "CPU" || currentAsset === "Laptop"
                        ? ""
                        : "hidden"
                    }`}
                  >
                    <div className="kr-selectform">
                      <label className="kr-form-label ">
                        Machine Type Model
                      </label>
                      <input
                        type="text"
                        placeholder="Machine Type Model"
                        value={selectedValue?.macId?.label}
                        name="MacId"
                        className={`form-control kr-form-textbox placeholder-[#212529]}`}
                        onChange={(e) => handleInputChange(e, "macId")}
                      ></input>
                    </div>
                  </div>

                  {currentAsset === "Laptop" && (
                    <div
                      className={`col-span-2 md:col-span-1 ${
                        currentAsset === "CPU" || currentAsset === "Laptop"
                          ? ""
                          : "hidden"
                      }`}
                    >
                      <div className="kr-selectform required">
                        <label className="kr-form-label ">Dimension</label>
                        <input
                          type="text"
                          placeholder="Dimension"
                          value={selectedValue?.dimension?.label}
                          name="dimension"
                          className={`form-control kr-form-textbox placeholder-[#212529] ${
                            dataError?.dimension === false ? "" : "dataError"
                          }`}
                          onChange={(e) => handleInputChange(e, "dimension")}
                        ></input>
                      </div>
                    </div>
                  )}
                  <div
                    className={`col-span-2 md:col-span-1 ${
                      currentAsset === "CPU" || currentAsset === "Laptop"
                        ? ""
                        : "hidden"
                    }`}
                  >
                    <div className="kr-selectform">
                      <label className="kr-form-label">BIOS Version</label>
                      <input
                        type="text"
                        value={selectedValue?.biosVersion?.label}
                        placeholder="BIOS Version"
                        name="bios"
                        className={`form-control kr-form-textbox placeholder-[#212529]`}
                        onChange={(e) => handleInputChange(e, "biosVersion")}
                      ></input>
                    </div>
                  </div>
                  {currentAsset === "Webcam" || currentAsset === "Monitor" ? (
                    ""
                  ) : (
                    <div className="col-span-2 md:col-span-1">
                      <div className="kr-selectform required">
                        <label className="kr-form-label">RAM</label>
                        <input
                          type="text"
                          placeholder="RAM"
                          value={selectedValue?.ram?.label}
                          className={`form-control kr-form-textbox placeholder-[#212529] ${
                            dataError.ram === false ? "" : "dataError"
                          }`}
                          onChange={(e) => handleInputChange(e, "ram")}
                        ></input>
                      </div>
                    </div>
                  )}
                  {currentAsset === "Webcam" || currentAsset === "Monitor" ? (
                    ""
                  ) : (
                    <div className="col-span-2 md:col-span-1">
                      <div className="kr-selectform required">
                        <label className="kr-form-label">Processor</label>
                        <input
                          type="text"
                          placeholder="Processor"
                          value={selectedValue?.processor?.label}
                          className={`form-control kr-form-textbox placeholder-[#212529] ${
                            dataError.processor === false ? "" : "dataError"
                          }`}
                          onChange={(e) => handleInputChange(e, "processor")}
                        ></input>
                      </div>
                    </div>
                  )}
                  {currentAsset === "Webcam" || currentAsset === "Monitor" ? (
                    ""
                  ) : (
                    <div className="col-span-2 md:col-span-1">
                      <div className="kr-selectform required">
                        <label className="kr-form-label">Generation</label>
                        <input
                          type="text"
                          placeholder="Generation"
                          value={selectedValue?.generation?.label}
                          className={`form-control kr-form-textbox placeholder-[#212529] ${
                            dataError.generation === false ? "" : "dataError"
                          }`}
                          onChange={(e) => handleInputChange(e, "generation")}
                        ></input>
                      </div>
                    </div>
                  )}
                  {currentAsset === "Webcam" || currentAsset === "Monitor" ? (
                    ""
                  ) : (
                    <div className="col-span-2 md:col-span-1">
                      <div className="kr-selectform required">
                        <label className="kr-form-label">Storage Type</label>
                        <Select
                          searchable={false}
                          backspaceDelete={false}
                          placeholder="Storage type"
                          name="StorageType"
                          values={[selectedValue?.storageType]}
                          className={`form-control kr-form-textbox ${
                            dataError.storageType === false ? "" : "dataError"
                          }`}
                          options={[
                            { value: "HDD", label: "HDD" },
                            { value: "SSD", label: "SSD" },
                          ]}
                          onChange={(selectedOption) =>
                            handleInputChange(selectedOption, "storageType")
                          }
                        />
                      </div>
                    </div>
                  )}
                  {currentAsset === "Webcam" || currentAsset === "Monitor" ? (
                    ""
                  ) : (
                    <div className="col-span-2 md:col-span-1">
                      <div className="kr-selectform required">
                        <label className="kr-form-label">Storage Size</label>
                        <input
                          type="text"
                          placeholder="Storage Size"
                          value={selectedValue?.storageSize?.label}
                          className={`form-control kr-form-textbox placeholder-[#212529] ${
                            dataError.storageSize === false ? "" : "dataError"
                          }`}
                          onChange={(e) => handleInputChange(e, "storageSize")}
                        ></input>
                      </div>
                    </div>
                  )}

                  <div className="col-span-2 md:col-span-1">
                    <div className="kr-selectform ">
                      <label className="kr-form-label">Purchase Date</label>
                      <input
                        min="2001-01"
                        max={`${new Date().toISOString().slice(0, 7)}`}
                        type="date"
                        placeholder="Purchase Date"
                        name="PurchaseDate"
                        value={selectedValue?.purchaseDate?.label}
                        className={`form-control kr-form-textbox placeholder-[#212529] 
                   
                      `}
                        onChange={(e) => handleInputChange(e, "purchaseDate")}
                        // onKeyDown={(e) => e.preventDefault()}
                      ></input>
                    </div>
                  </div>

                  <div className="col-span-2 md:col-span-1">
                    <div className="kr-selectform required">
                      <label className="kr-form-label">Warranty</label>
                      <Select
                        searchable={false}
                        backspaceDelete={false}
                        placeholder="Warranty"
                        name="isWarrantyTill"
                        values={[selectedValue?.isWarrantyTill]}
                        className={`form-control kr-form-textbox ${
                          dataError?.isWarrantyTill === false ? "" : "dataError"
                        }`}
                        options={[
                          { value: true, label: "Active" },
                          { value: false, label: "In Active" },
                        ]}
                        onChange={(selectedOption) => {
                          handleInputChange(selectedOption, "isWarrantyTill");
                        }}
                      />
                    </div>
                  </div>

                  {inputValues?.isWarrantyTill === true ? (
                    <div className="col-span-2 md:col-span-1">
                      <div className="kr-selectform">
                        <label className="kr-form-label">Warranty Till</label>
                        <input
                          min="2001-01"
                          max={`${new Date().toISOString().slice(0, 7)}`}
                          type="date"
                          placeholder="Warranty Till"
                          name="Warrantytill"
                          value={selectedValue?.warrantyTill?.label}
                          className={`form-control kr-form-textbox placeholder-[#212529] 
                    
                      `}
                          onChange={(e) => handleInputChange(e, "warrantyTill")}
                          // onKeyDown={(e) => e.preventDefault()}
                        ></input>
                      </div>
                    </div>
                  ) : null}

                  <div
                    className={`col-span-2 md:col-span-1 ${
                      currentAsset === "CPU" || currentAsset === "Laptop"
                        ? ""
                        : "hidden"
                    } `}
                  >
                    <div className="kr-selectform required">
                      <label className="kr-form-label">OS Type</label>
                      <Select
                        searchable={false}
                        backspaceDelete={false}
                        placeholder="OS Type"
                        name="windowsOS"
                        values={[selectedValue?.windowsOS]}
                        className={`form-control kr-form-textbox ${
                          dataError.windowsOS === false ? "" : "dataError"
                        }`}
                        options={[
                          { value: "Windows", label: "WINDOWS" },
                          { value: "Linux", label: "LINUX" },
                          { value: "Mac Os", label: "MAC OS" },
                        ]}
                        onChange={(selectedOption) => {
                          setSelectedValue((option) => ({
                            ...option,
                            isLicensed: {
                              value: null,
                              label:
                                selectedOption[0].value === "Linux"
                                  ? "License type"
                                  : "Licensed/Not",
                            },
                          }));

                          if (selectedOption[0].value === "Mac Os") {
                            setInputValues((input) => ({
                              ...input,
                              isLicensed: true,
                              windowsLicense: null,
                            }));
                          }

                          handleInputChange(selectedOption, "windowsOS");
                        }}
                      />
                    </div>
                  </div>
                  {currentAsset === "Webcam" || currentAsset === "Monitor" ? (
                    ""
                  ) : (
                    <div
                      className={`col-span-2 md:col-span-1 ${
                        selectedValue?.windowsOS.value === "Mac Os"
                          ? "hidden"
                          : ""
                      }`}
                    >
                      <div className="kr-selectform">
                        <label className="kr-form-label">
                          {inputValues.windowsOS === "Mac Os" ||
                          inputValues.windowsOS === "Windows" ||
                          inputValues.windowsOS === undefined ||
                          inputValues.windowsOS === null ||
                          inputValues.windowsOS === ""
                            ? "Licensed/Not"
                            : "License Type"}
                        </label>
                        <Select
                          searchable={false}
                          backspaceDelete={false}
                          placeholder={
                            inputValues.windowsOS === "Mac Os" ||
                            inputValues.windowsOS === "Windows" ||
                            inputValues.windowsOS === undefined ||
                            inputValues.windowsOS === null ||
                            ""
                              ? "Licensed/Not"
                              : "License Type"
                          }
                          name="Islicensed"
                          values={[selectedValue?.isLicensed]}
                          className={`form-control kr-form-textbox`}
                          options={
                            inputValues.windowsOS === "Linux"
                              ? [
                                  { value: true, label: "Paid Version" },
                                  { value: false, label: "Free Version" },
                                ]
                              : [
                                  { value: true, label: "Yes" },
                                  { value: false, label: "No" },
                                ]
                          }
                          onChange={(selectedOption) => {
                            handleInputChange(selectedOption, "isLicensed");

                            if (selectedOption[0].value === false) {
                              setInputValues((prevInputValues) => ({
                                ...prevInputValues,
                                windowsLicense: null,
                              }));
                            }
                          }}
                          disabled={selectedValue?.windowsOS.value === "Mac Os"}
                        />
                      </div>
                    </div>
                  )}

                  {currentAsset === "Monitor" ? (
                    ""
                  ) : inputValues?.isLicensed === true ? (
                    <div
                      className={`col-span-2 md:col-span-1 !hidden ${
                        inputValues.windowsOS === "Mac Os" ? "hidden" : ""
                      }`}
                    >
                      <div className="kr-selectform">
                        <label className="kr-form-label">
                          {inputValues.windowsOS === "Linux"
                            ? "Linux License"
                            : inputValues.windowsOS === "Mac Os"
                            ? "Mac License"
                            : "Windows License"}
                        </label>
                        <input
                          type="text"
                          placeholder={
                            inputValues.windowsOS === "Linux"
                              ? "Linux License"
                              : inputValues.windowsOS === "Mac Os"
                              ? "Mac License"
                              : "Windows License"
                          }
                          name="Windowslicense"
                          value={selectedValue?.windowsLicense?.label}
                          className={`form-control kr-form-textbox placeholder-[#212529]`}
                          onChange={(e) =>
                            handleInputChange(e, "windowsLicense")
                          }
                        ></input>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  {/* <div className="col-span-2 md:col-span-1">
                  <div className="kr-selectform required">
                    <label className="kr-form-label">Is Active</label>
                    <Select
                      searchable={false}
                      backspaceDelete={false}
                      placeholder="Is Active"
                      name="Isactive"
                      values={[selectedValue?.isActive]}
                      className={`form-control kr-form-textbox ${
                        dataError.isActive === false ? "" : "dataError"
                      }`}
                      options={[
                        { value: true, label: "Yes" },
                        { value: false, label: "No" },
                      ]}
                      onChange={(selectedOption) =>
                        handleInputChange(selectedOption, "isActive")
                      }
                    />
                  </div>
                </div> */}

                  {/* <div className="col-span-2 md:col-span-1">
                    <div className="kr-selectform">
                      <label className="kr-form-label">Created By</label>
                      <input
                        type="text"
                        placeholder="Created By"
                        name="Createdby"
                        value={selectedValue?.createdBy?.label}
                        className={`form-control kr-form-textbox placeholder-[#212529] 
                     
                        `}
                        onChange={(e) => handleInputChange(e, "createdBy")}
                        readOnly={sidebarAction === "edit"}
                      ></input>
                    </div>
                  </div> */}

                  {/* <div className="col-span-2 md:col-span-1">
                    <div className="kr-selectform ">
                      <label className="kr-form-label">Created On</label>
                      <input
                        type="text"
                        placeholder="Created On"
                        name="Createdon"
                        value={new Date().toISOString().substr(0, 10)}
                        className={`form-control kr-form-textbox placeholder-[#212529] 
                      
                      `}
                        onChange={(e) => handleInputChange(e, "createdOn")}
                        readOnly
                      ></input>
                    </div>
                  </div> */}

                  {/* {currentAsset === "Webcam" || currentAsset === "Monitor" ? (
                  ""
                ) : (
                  <div className="col-span-2 md:col-span-1">
                    <div className="kr-selectform required">
                      <label className="kr-form-label">Is Monitor</label>
                      <Select
                        searchable={false}
                        backspaceDelete={false}
                        placeholder="Is Monitor"
                        name="Ismonitor"
                        values={[selectedValue?.isMonitor]}
                        className={`form-control kr-form-textbox ${
                          dataError.isMonitor === false ? "" : "dataError"
                        }`}
                        options={[
                          { value: true, label: "Yes" },
                          { value: false, label: "No" },
                        ]}
                        onChange={(selectedOption) =>
                          handleInputChange(selectedOption, "isMonitor")
                        }
                      />
                    </div>
                  </div>
                )} */}

                  {currentAsset === "Webcam" || currentAsset === "Monitor" ? (
                    ""
                  ) : (
                    <div className="col-span-2 md:col-span-1">
                      <div className="kr-selectform required">
                        <label className="kr-form-label"> Keyboard</label>
                        <Select
                          searchable={false}
                          backspaceDelete={false}
                          placeholder="Keyboard"
                          name="Iskeyboard"
                          values={[selectedValue?.isKeyboard]}
                          className={`form-control kr-form-textbox ${
                            dataError.isKeyboard === false ? "" : "dataError"
                          }`}
                          options={[
                            { value: true, label: "Yes" },
                            { value: false, label: "No" },
                          ]}
                          onChange={(selectedOption) =>
                            handleInputChange(selectedOption, "isKeyboard")
                          }
                        />
                      </div>
                    </div>
                  )}

                  {currentAsset === "Webcam" || currentAsset === "Monitor" ? (
                    ""
                  ) : (
                    <div className="col-span-2 md:col-span-1">
                      <div className="kr-selectform required">
                        <label className="kr-form-label">Mouse</label>
                        <Select
                          searchable={false}
                          backspaceDelete={false}
                          placeholder="Mouse"
                          name="Ismouse"
                          values={[selectedValue?.isMouse]}
                          className={`form-control kr-form-textbox ${
                            dataError.isMouse === false ? "" : "dataError"
                          }`}
                          options={[
                            { value: true, label: "Yes" },
                            { value: false, label: "No" },
                          ]}
                          onChange={(selectedOption) =>
                            handleInputChange(selectedOption, "isMouse")
                          }
                        />
                      </div>
                    </div>
                  )}

                  {currentAsset === "Webcam" || currentAsset === "Monitor" ? (
                    ""
                  ) : (
                    <div className="col-span-2 md:col-span-1">
                      <div className="kr-selectform required">
                        <label className="kr-form-label"> Headset</label>
                        <Select
                          searchable={false}
                          backspaceDelete={false}
                          placeholder="Headset"
                          name="Isheadset"
                          values={[selectedValue?.isHeadSet]}
                          className={`form-control kr-form-textbox ${
                            dataError.isHeadSet === false ? "" : "dataError"
                          }`}
                          options={[
                            { value: true, label: "Yes" },
                            { value: false, label: "No" },
                          ]}
                          onChange={(selectedOption) =>
                            handleInputChange(selectedOption, "isHeadSet")
                          }
                        />
                      </div>
                    </div>
                  )}

                  {currentAsset === "Webcam" || currentAsset === "Monitor" ? (
                    ""
                  ) : (
                    <div className="col-span-2 md:col-span-1">
                      <div className="kr-selectform required">
                        <label className="kr-form-label">AV Installed</label>
                        <Select
                          searchable={false}
                          backspaceDelete={false}
                          placeholder="AV Installed"
                          name="Isavinstalled"
                          values={[selectedValue?.isAVInstalled]}
                          className={`form-control kr-form-textbox ${
                            dataError.isAVInstalled === false ? "" : "dataError"
                          }`}
                          options={[
                            { value: true, label: "Yes" },
                            { value: false, label: "No" },
                          ]}
                          onChange={(selectedOption) =>
                            handleInputChange(selectedOption, "isAVInstalled")
                          }
                        />
                      </div>
                    </div>
                  )}

                  <div className="col-span-2 md:col-span-1">
                    <div className="kr-selectform required">
                      <label className="kr-form-label">State</label>
                      <Select
                        searchable={false}
                        backspaceDelete={false}
                        placeholder="State"
                        name="State"
                        values={[selectedValue?.isActive]}
                        className={`form-control kr-form-textbox ${
                          dataError.isActive === false ? "" : "dataError"
                        }`}
                        options={[
                          { value: true, label: "Active" },
                          { value: false, label: "In Active" },
                        ]}
                        onChange={(selectedOption) =>
                          handleInputChange(selectedOption, "isActive")
                        }
                      />
                    </div>
                  </div>

                  {currentAsset === "Webcam" || currentAsset === "Monitor" ? (
                    ""
                  ) : (
                    <div className="col-span-2 md:col-span-1">
                      <div className="kr-selectform">
                        <label className="kr-form-label">IP Address</label>
                        <input
                          type="text"
                          placeholder="Ip Address"
                          name="Ipaddress"
                          value={selectedValue?.ipAddress?.label}
                          className={`form-control kr-form-textbox placeholder-[#212529]`}
                          onChange={(e) => handleInputChange(e, "ipAddress")}
                        ></input>
                      </div>
                    </div>
                  )}

                  <div className="col-span-2 md:col-span-1">
                    <div className="kr-selectform">
                      <label className="kr-form-label">Remarks</label>
                      <input
                        type="text"
                        placeholder="Remarks"
                        name="Remarks"
                        value={selectedValue?.remarks?.label}
                        className={`form-control kr-form-textbox placeholder-[#212529] 
                 
                      `}
                        onChange={(e) => handleInputChange(e, "remarks")}
                      ></input>
                    </div>
                  </div>

                  <div className="col-span-2">
                    <div className="kr-selectform flex justify-end">
                      {/* <Link to ='/' className='no-underline'> */}
                      <button
                        type="button"
                        className="flex items-center justify-center py-[6px] px-[12px] btn-light border border-solid border-gray-200 rounded min-w-80"
                        onClick={handleSidebarClose}
                      >
                        Cancel
                      </button>
                      {/* </Link> */}
                      {/* <Link to ='/' className='no-underline'> */}
                      <button
                        type="button"
                        className="btn btn-primary flex items-center justify-center ml-2 min-w-80"
                        onClick={handleSidebarFormSubmit}
                      >
                        {sidebarAction === "add" ? "Add" : "Update"}
                      </button>
                      {/* </Link> */}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
}

export default AssetManagement;
